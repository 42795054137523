<template>
  <div class="checkbox-detail">
    <h1 class="checkbox-detail__title">
      Отчет об установлении и/или изменении кадастровой стоимости объекта
      недвижимости
    </h1>
    <p class="description"></p>
    <ul>
      <li>
        <span class="detail-icon"></span>
        <b></b>
      </li>
      <li>
        <span class="detail-icon"></span>
        <b></b>
      </li>
      <li>
        <span class="detail-icon"></span>
        <b></b>
      </li>
      <li>
        <span class="detail-icon"></span>
        <b></b>
      </li>
      <li>
        <span class="detail-icon"></span>
        <b></b>
      </li>
      <li>
        <span class="detail-icon"></span>
        <b></b>
      </li>
      <li>
        <span class="detail-icon"></span>
        <b></b>
      </li>
      <li>
        <span class="detail-icon"></span>
        <b></b>
      </li>
      <li>
        <span class="detail-icon"></span>
        <b></b>
      </li>
    </ul>
    <div class="info">
      <div class="price">
        <p>Стоимость:</p>
        <h6>{{ cost }} ₽</h6>
      </div>
      <div class="term">
        <p>Срок исполнения:</p>
        <h6></h6>
      </div>
      <div class="format">
        <p>Формат:</p>
        <h6>Электронный документ</h6>
      </div>
    </div>
  </div>
</template>

<script>
import popup from '@/mixins/popup';
import './checkboxDetail.scss';

export default {
  name: 'CheckboxDetailSKS',
  mixins: [popup],
  computed: {
    cost() {
      return this.$store.state.config.cost.SKS;
    }
  }
};
</script>
